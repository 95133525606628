import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { LoadingButton } from '@mui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Card,
  Stack,
  Container,
  TextField,
  Typography,
  IconButton,
  Autocomplete,
  InputAdornment,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { countryCodes } from 'src/assets/data/countryCodes';
import { CheckUserName, memberRegisterApi } from 'src/server/api/auth';

import Iconify from 'src/components/iconify';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

export default function RegisterView() {
  const isMobile = useResponsive('down', 'md');
  const location = useLocation();

  const ReferralId = location?.search?.split('?ref_id=')[1];
  console.log(ReferralId);
  const router = useRouter();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const password = useBoolean();

  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [userNameCheck, setUserNameCheck] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);

  const { userNamesCheck, loading } = useSelector((state) => ({
    userNamesCheck: state.auth.CheckUserNames,

    loading: state.auth.loginLoading,
  }));
  const registerSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
    referral_id: Yup.string(),
    // company_name: Yup.string(),
    username: Yup.string(),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    referral_id: ReferralId || '',
    // email: account?.email || '',
    // phone: account?.phone || '',
  };
  console.log(selectedCountryCode);
  const methods = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues,
  });

  const {
    // setValue,
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        ...data,
        name: data?.name,
        phone: data?.phone,
        country_code: selectedCountryCode || '',
        referral_id: data?.referral_id,
        // company_name: data?.company_name,
        username: userNameCheck,
        password: data?.password,
        email: data?.email,
      },
      dispatch,
      navigate,
    };
    dispatch(memberRegisterApi(credentials));
  });

  const optionCountry = [
    ...(countryCodes || []).map((results) => ({
      label: `${results?.code} ${results?.name}`,
      // label: results?.code,
      value: results?.code,
    })),
  ];
  const handleAutocompleteChangeCountry = (e, newValue) => {
    setSelectedCountryCode(newValue?.value || '');
  };

  const handleTextFieldChangeCourse = (event) => {
    const searchText = event.target.value.trim().toLowerCase();
    const filteredOptions = countryCodes
      .filter((country) => country.name && country.name.trim().toLowerCase().includes(searchText))
      .map((result) => ({
        label: result.name,
        value: result.code,
      }));
    console.log(filteredOptions);
  };
  const handleUserNameChange = (event) => {
    const { value } = event.target;
    console.log('User Name Value:', value);
    setUserNameCheck(value);
  };

  useEffect(() => {
    if (userNameCheck) {
      const credential = {
        state: {
          username: userNameCheck,
        },
      };
      dispatch(CheckUserName(credential));
    }
  }, [dispatch, userNameCheck]);

  const HandleLogin = () => {
    router.push({
      pathname: paths?.auth?.login_investor,
    });
  };
  const renderForm = (
    <>
      <Box
        rowGap={2}
        columnGap={2}
        display="grid"
        marginBottom={2}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <RHFTextField
          name="name"
          label="Name"
          required
          placeholder="Enter full name"
          InputLabelProps
        />
        <RHFTextField
          name="referral_id"
          label="Referral ID"
          placeholder="Enter Referral ID (optional)"
          InputLabelProps
        />
      </Box>
      <Box
        rowGap={2}
        columnGap={2}
        marginBottom={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <Autocomplete
          id="country-select-demo"
          sx={{ width: '100%' }}
          options={optionCountry}
          autoHighlight
          size="medium"
          // className="small-select"
          getOptionLabel={(option) => option.label}
          onChange={handleAutocompleteChangeCountry}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              placeholder="Select Country with code"
              InputLabelProps={{ shrink: true }}
              onChange={handleTextFieldChangeCourse}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
        <RHFTextField
          sx={{ width: '100%' }}
          name="phone"
          type="number"
          label="Phone"
          placeholder="Enter phone number"
          onInput={(e) => {
            const input = e.target.value.replace(/\D/g, '');
            const limitedInput = input.slice(0, 10);
            e.target.value = limitedInput;
          }}
          InputLabelProps={{ shrink: true }}
        />

        {/* <RHFTextField
sx={{paddheight:'19px'ame="company_name"
          type="text"
          label="Company name"
          placeholder="Enter company"
          InputLabelProps
        /> */}
      </Box>

      {/* <Box
        rowGap={2}
        columnGap={2}
        display="grid"
        marginBottom={2}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <RHFTextField
sx={{height:'19px'}}
        name="date_of_birth"
          label="Date of Birth"
          type="date"
          placeholder="Date of Birth"
          InputLabelProps={{ shrink: true }}
        />
      </Box> */}
      <Box
        rowGap={2}
        columnGap={2}
        display="grid"
        marginBottom={2}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <Stack>
          <RHFTextField
            name="username"
            label="Username"
            placeholder="Enter username"
            InputLabelProps
            required
            onChange={handleUserNameChange}
            value={userNameCheck}
          />
          {userNameCheck && (
            <>
              {userNamesCheck === false && (
                <Typography
                  color="#FF5630"
                  fontSize={12}
                  fontWeight={400}
                  display="flex"
                  alignItems="center"
                  ml={2}
                >
                  {/* <Iconify icon="solid/ic-eva:alert-triangle-fill" />  */}
                  <WarningIcon
                    sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }}
                  />{' '}
                  username already taken
                </Typography>
              )}
              {userNamesCheck === true && (
                <Typography
                  sx={{
                    color: 'success.main',
                    fontSize: '10px',
                    fontWeight: '600',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Verified <CheckCircleIcon sx={{ fontSize: '14px', marginLeft: '4px' }} />
                </Typography>
              )}
            </>
          )}
        </Stack>
        <RHFTextField
          name="email"
          type="email"
          label="Email"
          placeholder="Enter email address"
          InputLabelProps={{ shrink: true }}
          required
        />
      </Box>
      <Box
        rowGap={2}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        }}
      >
        <RHFTextField
          name="password"
          label="Password"
          placeholder="Enter Password"
          InputLabelProps
          type={password.value ? 'text' : 'password'}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="ConfirmPassword"
          type={password.value ? 'text' : 'password'}
          label="Confirm"
          placeholder="Enter Confirm Password"
          InputLabelProps={{ shrink: true }}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            const confirmPasswordValue = e.target.value;
            const passwordValue = methods.getValues('password');
            setPasswordMatch(passwordValue === confirmPasswordValue);
          }}
        />
      </Box>
      <Box>
        {!passwordMatch && (
          <Stack textAlign="right">
            <Typography
              variant="body2"
              color="error"
              display="flex"
              alignItems="center"
              justifyContent="end"
              mr={2}
            >
              <WarningIcon sx={{ fontSize: '14px', marginRight: '4px', color: '#FF5630' }} />{' '}
              Password does not match
            </Typography>
          </Stack>
        )}
      </Box>
      <Stack spacing={3} marginY={2}>
        <LoadingButton
          type="submit"
          variant="contained"
          color="success"
          loading={isSubmitting || loading}
        >
          Register{' '}
        </LoadingButton>
      </Stack>
    </>
  );
  return (
    <Container maxWidth="lg">
      <MetaHelmet title="RegisterView" />

      <Stack maxWidth={610} marginX="auto">
        <Stack mb={5}>
          <Typography fontSize={isMobile ? 20 : 24} fontWeight={700}>
            Member Registration{' '}
          </Typography>
          <Typography
            fontSize={isMobile ? 12 : 14}
            fontWeight={400}
            mt="14px"
            color="#637381"
            display="flex"
          >
            Already registered?
            <Typography
              fontSize={isMobile ? 12 : 14}
              color="#00A76F"
              ml="5px"
              fontWeight={700}
              sx={{ cursor: 'pointer' }}
              onClick={HandleLogin}
            >
              Login
            </Typography>
          </Typography>
        </Stack>
        <Card sx={{ p: 2, mt: 2 }}>
          <Stack p={isMobile ? 0 : 1} py={isMobile ? 3 : 3}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {renderForm}
            </FormProvider>
            <Stack spacing={3} alignItems="center">
              <Typography fontSize={12} fontWeight={400} color="#637381" display="flex">
                By registering, I agree to
                <Typography
                  mx="5px"
                  fontSize={12}
                  fontWeight={400}
                  sx={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}
                >
                  {' '}
                  Terms & Conditions{' '}
                </Typography>{' '}
                and{' '}
                <Typography
                  mx="5px"
                  fontSize={12}
                  fontWeight={400}
                  sx={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}
                >
                  {' '}
                  Privacy Policy
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
