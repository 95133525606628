import { Stack, Container } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { useSettingsContext } from 'src/components/settings';

import InvestorList from 'src/sections/app/adminDashboard/InvestorList';
// ----------------------------------------------------------------------

// const SPACING = 2;

export default function AdminDashboard() {
  const settings = useSettingsContext();
  // const [ImagePreview, setImagePreview] = useState([]);

  const isMobile = useResponsive('down', 'md');

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack px={isMobile ? 0 : 5}>
        <InvestorList />
      </Stack>
    </Container>
  );
}

AdminDashboard.propTypes = {};
