import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import TablePagination from '@mui/material/TablePagination';
import FormControlLabel from '@mui/material/FormControlLabel';

// ----------------------------------------------------------------------

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [1, 5, 10, 25],
  sx,
  identifier, // Unique identifier for this table
  ...other
}) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // console.log(identifier);
  useEffect(() => {
    // Check if rows per page count is stored in session storage for this identifier
    const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
    setRowsPerPage(parseInt(storedRowsPerPage ?? rowsPerPage, 10));
  }, [identifier, rowsPerPage]);

  // console.log(rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    sessionStorage.setItem(`rowsPerPage_${identifier}`, newRowsPerPage);
    if (other.onRowsPerPageChange) {
      other.onRowsPerPageChange(event);
    }
  };

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
        rowsPerPage={rowsPerPage}
        onPageChange={other.onPageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          borderTopColor: 'transparent',
        }}
      />

      {onChangeDense && (
        <FormControlLabel
          label="Dense"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: 'absolute',
            },
          }}
        />
      )}
    </Box>
  );
}

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  identifier: PropTypes.string, // Unique identifier for this table
};
