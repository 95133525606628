import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { alpha } from '@mui/material/styles';
import { Box, Stack, Button, TextField, CardMedia, Typography, Autocomplete } from '@mui/material';

import useResponsive from 'src/hooks/useResponsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import warning from 'src/assets/other/warning.png';
import { countryCodes } from 'src/assets/data/countryCodes';
import { getAllState, getAllDistrict } from 'src/server/api/location';
import { investorCreate, updateInvestorDetails } from 'src/server/api/investor';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

export default function InvestorCrudModalContent({
  activeStep,
  handleNextStep,
  handleClose,
  isUpdate,
  handlePreviousStep,
}) {
  const isMobile = useResponsive('down', 'md');
  const { role } = useGetRoles();
  const { loading, districtList, stateList } = useSelector((state) => ({
    loading: state.auth.loginLoading,
    stateList: state.location.states,
    districtList: state.location.districts,
  }));

  const [formattedCountryCode, setFormattedCountryCode] = useState('');
  const updateCoutryCode = countryCodes?.find((item) => item?.code === formattedCountryCode);
  // state
  const [selectedState, setSelectedState] = useState({
    label: '',
    value: '',
  });
  const [searchValueState, setSearchValueState] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState({
    label: '',
    value: '',
  });
  const [error, setError] = useState('');

  // district
  const [selectedDistrict, setSelectedDistrict] = useState({
    label: '',
    value: '',
  });
  const [searchValueDistrict, setSearchValueDistrict] = useState('');
  const defaultState = useMemo(
    () => ({
      label: isUpdate?.state_name || '',
      value: isUpdate?.state || '',
    }),
    [isUpdate]
  );
  const defaultDistrict = useMemo(
    () => ({
      label: isUpdate?.district_name || '',
      value: isUpdate?.district || '',
    }),
    [isUpdate]
  );
  // const defaultCountryCode = useMemo(
  //   () => ({
  //     label:
  //       (updateCoutryCode?.code &&
  //         updateCoutryCode?.name &&
  //         ` ${updateCoutryCode?.code} ${updateCoutryCode?.name}`) ||
  //       'Select',
  //     value: updateCoutryCode?.code || '',
  //   }),
  //   [updateCoutryCode?.code, updateCoutryCode?.name]
  // );
  useEffect(() => {
    setSelectedState({
      label: isUpdate?.state_name || '',
      value: isUpdate?.state || '',
    });
    setSelectedDistrict({
      label: isUpdate?.district_name || '',
      value: isUpdate?.district || '',
    });
    setSelectedCountryCode({
      label:
        (updateCoutryCode?.code &&
          updateCoutryCode?.name &&
          ` ${updateCoutryCode?.code} ${updateCoutryCode?.name}`) ||
        'Select',
      value: updateCoutryCode?.code || '',
    });
    setFormattedCountryCode(`+${isUpdate?.country_code}`);
  }, [
    isUpdate?.country_code,
    isUpdate?.district,
    isUpdate?.district_name,
    isUpdate?.state,
    isUpdate?.state_name,
    updateCoutryCode?.code,
    updateCoutryCode?.name,
  ]);

  const dispatch = useDispatch();
  const investorSchema = Yup.object().shape({
    full_name: Yup.string().required('Name is required'),
    dob: Yup.string(),
    phone: Yup.string().required('Phone is required'),
    // country_code: Yup.string().required('Country Code is required'),
    // country_code: Yup.object().shape({
    //   label: Yup.string(),
    //   value: Yup.string().required('Country Code is required'),
    // }),
    email: Yup.string(),
    number_of_shares: Yup.number(),
    certificate_number: Yup.string(),
    rewards: Yup.number(),
    dividend: Yup.number(),
    address_line1: Yup.string(),
    address_line2: Yup.string(),
    post_office: Yup.string(),
    pincode: Yup.string(),
    state: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    district: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    bank_holder_name: Yup.string(),
    bank_name: Yup.string(),
    // bank_account_number: Yup.number().max(17, 'Account number must be at most 17 digits'),
    bank_account_number: Yup.string().matches(
      /^\d{1,17}$/,
      'Account number must be at most 17 digits'
    ),

    bank_ifsc: Yup.string(),
  });
  const adminSchema = Yup.object().shape({
    full_name: Yup.string().required('Name is required'),
    dob: Yup.string(),
    phone: Yup.string().required('Phone is required'),
    // country_code: Yup.string().required('Country Code is required'),
    // country_code: Yup.object().shape({
    //   label: Yup.string(),
    //   value: Yup.string().required('Country Code is required'),
    // }),
    email: Yup.string(),
    number_of_shares: Yup.number(),
    certificate_number: Yup.string(),
    rewards: Yup.number(),
    dividend: Yup.number(),
    address_line1: Yup.string(),
    address_line2: Yup.string(),
    post_office: Yup.string(),
    pincode: Yup.string(),
    state: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    district: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
    bank_holder_name: Yup.string(),
    bank_name: Yup.string(),
    // bank_account_number: Yup.number().max(17, 'Account number must be at most 17 digits'),
    bank_account_number: Yup.string(),
    bank_ifsc: Yup.string(),
  });

  const formattedDob = isUpdate?.dob ? moment(isUpdate?.dob).format('YYYY-MM-DD') : '';

  const defaultValues = useMemo(() => {
    if (isUpdate?.id) {
      return {
        full_name: isUpdate?.full_name || '',
        dob: formattedDob || '',
        phone: isUpdate?.phone || '',
        email: isUpdate?.email || '',
        state: defaultState || {},
        district: defaultDistrict || {},
        // country_code: defaultCountryCode || {},
        number_of_shares: isUpdate?.number_of_shares || 0,
        certificate_number: isUpdate?.certificate_number || '',
        rewards: isUpdate?.rewards || 0,
        dividend: isUpdate?.dividend || 0,
        address_line1: isUpdate?.address_line1 || '',
        address_line2: isUpdate?.address_line2 || '',
        post_office: isUpdate?.post_office || '',
        pincode: isUpdate?.pincode || '',
        bank_holder_name: isUpdate?.bank_holder_name || '',
        bank_name: isUpdate?.bank_name || '',
        bank_account_number: isUpdate?.bank_account_number || '',
        bank_ifsc: isUpdate?.bank_ifsc || '',
      };
    }
    return {
      full_name: '',
      dob: '',
      phone: '',
      email: '',
      number_of_shares: 0,
      certificate_number: '',
      rewards: 0,
      dividend: 0,
      address_line1: '',
      address_line2: '',
      post_office: '',
      pincode: '',
      bank_holder_name: '',
      bank_name: '',
      bank_account_number: '',
      bank_ifsc: '',
      // country_code: {},
    };
  }, [
    isUpdate?.id,
    isUpdate?.full_name,
    isUpdate?.phone,
    isUpdate?.email,
    isUpdate?.number_of_shares,
    isUpdate?.certificate_number,
    isUpdate?.rewards,
    isUpdate?.dividend,
    isUpdate?.address_line1,
    isUpdate?.address_line2,
    isUpdate?.post_office,
    isUpdate?.pincode,
    isUpdate?.bank_holder_name,
    isUpdate?.bank_name,
    isUpdate?.bank_account_number,
    isUpdate?.bank_ifsc,
    formattedDob,
    defaultState,
    defaultDistrict,
    // defaultCountryCode,
  ]);
  // console.log( isUpdate)
  const getSchemaForRole = (rol) => {
    if (rol === 'admin') {
      return adminSchema;
    }
    return investorSchema;
  };
  const selectedSchema = getSchemaForRole(role);

  const methods = useForm({
    resolver: yupResolver(selectedSchema),
    defaultValues,
  });

  const {
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const watchIFSC = watch('bank_ifsc', '');

  useEffect(() => {
    if (watchIFSC) {
      const capitalized = watchIFSC.toUpperCase();
      if (watchIFSC !== capitalized) {
        setValue('bank_ifsc', capitalized);
      }
    }
  }, [watchIFSC, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    const createCredentials = {
      state: {
        ...data,
        email: data?.email || null,
        dob: data?.dob || null,
        state: selectedState?.value,
        district: selectedDistrict?.value,
        country_code: selectedCountryCode?.value.split('+')[1],
      },
      dispatch,
      handleClose,
    };
    const updateCredentials = {
      state: {
        ...data,
        email: data?.email || null,
        dob: data?.dob || null,
        state: selectedState?.value,
        district: selectedDistrict?.value,
        country_code: selectedCountryCode?.value.split('+')[1],
      },
      investorId: isUpdate?.id,
      dispatch,
      handleClose,
    };
    if (isUpdate?.id) {
      dispatch(updateInvestorDetails(updateCredentials));
    } else {
      dispatch(investorCreate(createCredentials));
    }
    if (!selectedCountryCode?.value) {
      setError('Country Code Is Required');
    }
  });

  useEffect(() => {
    reset({
      dob: formattedDob,
    });
  }, [reset, isUpdate, formattedDob]);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  // state
  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchValueState,
      limit: 10,
      dispatch,
    };
    dispatch(getAllState(credentials));
  }, [dispatch, searchValueState]);

  const handleAutocompleteChangeState = (_, newValue) => {
    // setSelectedState(newValue?.value || '');
    setSelectedState({
      label: newValue?.label || '',
      value: newValue?.value || '',
    });
  };
  const handleTextFieldChangeState = (event) => {
    setSearchValueState(event.target.value);
  };
  const optionState = [
    ...(stateList?.results || []).map((results) => ({
      label: results?.name,
      value: results?.id,
    })),
  ];

  // district
  useEffect(() => {
    if (selectedState?.value) {
      const credentials = {
        page: 1,
        search: searchValueDistrict,
        limit: 10,
        dispatch,
        state: selectedState?.value,
      };
      dispatch(getAllDistrict(credentials));
    }
  }, [dispatch, searchValueDistrict, selectedState]);

  const handleAutocompleteChangeDistrict = (_, newValue) => {
    // setSelectedDistrict(newValue?.value || '');
    setSelectedDistrict({
      label: newValue?.label || '',
      value: newValue?.value || '',
    });
  };
  const handleTextFieldChangeDistrict = (event) => {
    setSearchValueDistrict(event.target.value);
  };

  const optionDistrict = [
    ...(districtList?.results || []).map((results) => ({
      label: results?.name,
      value: results?.id,
    })),
  ];
  // console.log(selectedCountryCode);
  const optionCountry = [
    ...(countryCodes || []).map((results) => ({
      label: `${results?.code} ${results?.name}`,
      value: results?.code,
    })),
  ];
  const handleAutocompleteChangeCountry = (e, newValue) => {
    // setSelectedCountryCode(newValue?.value || '');
    setSelectedCountryCode({
      label: newValue?.label || '',
      value: newValue?.value || '',
    });
  };
  console.log(error);
  const renderFormAddress = (
    <>
      <Stack p={3} direction="column" spacing={2}>
        <RHFTextField
          name="address_line1"
          label="Address Line 1"
          type="text"
          placeholder="Enter address"
        />
        <RHFTextField
          name="address_line2"
          label="Address Line 2"
          type="text"
          placeholder="Enter address"
        />

        <Stack sx={{ flexDirection: 'column', gap: '15px' }}>
          <Stack flexDirection="row" spacing={2}>
            <Stack width="100%">
              <RHFTextField
                name="post_office"
                label="Post office"
                placeholder="Enter post office "
              />
            </Stack>
            <Autocomplete
              id="state-select-demo"
              sx={{ width: '100%' }}
              options={optionState}
              // defaultValue={defaultState}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={selectedState}
              onChange={handleAutocompleteChangeState}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.label}
                </Box>
              )}
              renderInput={(param) => (
                <TextField
                  {...param}
                  label="State"
                  // required
                  name="product_region.state"
                  value={selectedState}
                  onChange={handleTextFieldChangeState}
                  inputProps={{
                    ...param.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />{' '}
          </Stack>
          <Stack sx={{ flexDirection: 'column', gap: '15px' }}>
            <Stack flexDirection="row" spacing={2}>
              <Autocomplete
                id="district-select-demo"
                sx={{ width: '100%' }}
                options={optionDistrict}
                // defaultValue={defaultDistrict}
                autoHighlight
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={selectedDistrict}
                getOptionLabel={(option) => option.label}
                onChange={handleAutocompleteChangeDistrict}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(param) => (
                  <TextField
                    {...param}
                    label="District"
                    // required
                    value={selectedDistrict}
                    name="product_region.district"
                    onChange={handleTextFieldChangeDistrict}
                    inputProps={{
                      ...param.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <Stack width="100%">
                <RHFTextField
                  name="pincode"
                  label="Pincode"
                  type="number"
                  placeholder="Enter pincode"
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        p={3}
        flexDirection="row"
        gap={2}
        justifyContent="end"
        sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
      >
        <Button
          variant="outlined"
          sx={{ height: '28px', border: 'unset' }}
          onClick={handlePreviousStep}
        >
          Previous
        </Button>
        <LoadingButton
          fullWidth
          size="small"
          // type="submit"
          variant="contained"
          color="success"
          sx={{ backgroundColor: 'success.main', width: '80px', height: '28px' }}
          loading={isSubmitting || loading}
          onClick={handleNextStep}
        >
          Continue
        </LoadingButton>
        <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </>
  );

  const renderFormProfile = (
    <>
      <Stack p={3} flexDirection="column" spacing={3}>
        <Stack sx={{ flexDirection: 'column', gap: '15px' }}>
          <RHFTextField
            name="full_name"
            label="Name"
            type="text"
            required
            placeholder="Enter full name"
          />
          <Stack flexDirection="row" spacing={2} mt={1}>
            <Stack width="100%">
              <Autocomplete
                id="country-select-demo"
                sx={{ width: '100%' }}
                options={optionCountry}
                autoHighlight
                size="medium"
                // className="small-select"
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                value={selectedCountryCode}
                onChange={handleAutocompleteChangeCountry}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country"
                    required
                    // placeholder={isUpdate?.country_code ? `+${isUpdate?.country_code}` : 'Select'}
                    InputLabelProps={{ shrink: true }}
                    // onChange={handleTextFieldChangeCountry}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                  />
                )}
              />
            </Stack>
            <Stack width="100%">
              <RHFTextField
                sx={{ width: '100%' }}
                name="phone"
                type="number"
                required
                label="Phone Number"
                placeholder="Enter phone number"
                // onInput={(e) => {
                //   const input = e.target.value.replace(/\D/g, '');
                //   const limitedInput = input.slice(0, 10);
                //   e.target.value = limitedInput;
                // }}
              />{' '}
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ flexDirection: 'column', gap: '15px' }}>
          <Stack flexDirection="row" spacing={2}>
            <Stack width="100%">
              <RHFTextField
                required
                name="email"
                type="email"
                label="Email"
                placeholder="Enter email address"
              />
            </Stack>
            <Stack width="100%">
              {' '}
              <RHFTextField
                sx={{ width: '100%' }}
                name="dob"
                label="Date of Birth"
                type="date"
                InputLabelProps={{ shrink: true }}
                defaultValue={defaultValues.dob}
              />
            </Stack>
          </Stack>
        </Stack>
        <Typography my={0.5} variant="subtitle2" color="#919EAB">
          Share
        </Typography>
        <Stack sx={{ flexDirection: 'column', gap: '15px' }}>
          <Stack flexDirection="row" spacing={2}>
            <Stack width="100%">
              <RHFTextField
                name="number_of_shares"
                type="number"
                label="No.of shares"
                disabled={role !== 'admin'}
                placeholder="Enter shares count"
              />
            </Stack>
            <Stack width="100%">
              {' '}
              <RHFTextField
                sx={{ width: '100%' }}
                name="certificate_number"
                label="Share Certificate Number"
                placeholder="Eg:Qc280"
                disabled={role !== 'admin'}
                type="text"
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ flexDirection: 'column', gap: '15px' }}>
          <Stack flexDirection="row" spacing={2}>
            <Stack width="100%">
              <RHFTextField
                name="rewards"
                type="number"
                label="Reward Points"
                disabled={role !== 'admin'}
                placeholder="Enter reward points "
              />
            </Stack>
            <Stack width="100%">
              {' '}
              <RHFTextField
                sx={{ width: '100%' }}
                name="dividend"
                placeholder="Enter dividend"
                label="Dividend"
                disabled={role !== 'admin'}
                type="number"
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        p={3}
        flexDirection="row"
        gap={2}
        justifyContent="end"
        sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
      >
        <LoadingButton
          fullWidth
          size="small"
          // type="submit"
          variant="contained"
          color="success"
          sx={{ backgroundColor: 'success.main', width: '80px', height: '28px' }}
          loading={isSubmitting || loading}
          onClick={handleNextStep}
        >
          Continue
        </LoadingButton>
        <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </>
  );
  const renderFormBank = (
    <>
      <Stack p={3} flexDirection="column" spacing={2}>
        <Stack alignItems="center">
          <Typography color="red" variant="caption" fontSize={12}>
            {errors?.full_name?.message && <>{errors?.full_name?.message}, </>}
            {errors?.phone?.message && <> {errors?.phone?.message},</>}
            {error && error}
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <RHFTextField
            name="bank_holder_name"
            label="Enter Account holder name"
            type="text"
            placeholder="Enter account holder name"
            required={role === 'investor'}
          />
          <RHFTextField
            name="bank_name"
            label="Bank name with branch"
            type="text"
            placeholder="Enter bank name/branch name"
            required={role === 'investor'}
          />
          <RHFTextField
            name="bank_account_number"
            label="Account Number"
            type="text"
            placeholder="Enter account number"
            required={role === 'investor'}
          />
          <Stack>
            <RHFTextField
              name="bank_ifsc"
              label="IFSC Code"
              type="text"
              placeholder="Enter IFSC Code"
              required={role === 'investor'}
            />
            <Stack flexDirection="row" pt={1} alignItems="center" ml={2}>
              <CardMedia
                component="img"
                src={warning}
                alt=""
                sx={{ width: '14px', height: '12px', mr: 0.8 }}
              />
              <Typography variant="caption" color="#637381">
                Savings bank/NRO account only
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        p={3}
        flexDirection="row"
        gap={2}
        justifyContent="end"
        sx={{ borderTop: (themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}` }}
      >
        <Button
          variant="outlined"
          sx={{ height: '28px', border: 'unset' }}
          onClick={handlePreviousStep}
        >
          Previous
        </Button>
        <LoadingButton
          fullWidth
          size="small"
          type="submit"
          variant="contained"
          color="success"
          sx={{ backgroundColor: 'success.main', width: '80px', height: '28px' }}
          loading={isSubmitting || loading}
        >
          {isUpdate?.id ? 'Update' : 'Create'}
        </LoadingButton>
        <Button variant="outlined" sx={{ height: '28px' }} onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </>
  );
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {activeStep === 0 && (
        <Stack flexDirection={isMobile ? 'column' : 'column'} spacing={2}>
          {renderFormProfile}
        </Stack>
      )}
      {activeStep === 1 && <Stack mt={2}>{renderFormAddress}</Stack>}
      {activeStep === 2 && <Stack mt={2}>{renderFormBank}</Stack>}
    </FormProvider>
  );
}

InvestorCrudModalContent.propTypes = {
  activeStep: PropTypes.any,
  handleNextStep: PropTypes.any,
  handlePreviousStep: PropTypes.any,
  handleClose: PropTypes.any,
  isUpdate: PropTypes.any,
};
