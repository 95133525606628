import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

import { LoginAdminApi } from 'src/server/api/auth';

// eslint-disable-next-line import/no-unresolved
import LogoLg from 'src/components/logo/logoLg';

import Iconify from '../../components/iconify';
// import { useAuthContext } from '../../hooks/use-auth-context';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

export default function JwtLoginView({ role }) {
  // const { login } = useAuthContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'md');

  // const router = useRouter();

  const [errorMsg] = useState('');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email or Username is required'),
    password: Yup.string().required('Password is required'),
  });
  const {  loading } = useSelector((state) => ({
    loginData: state.auth.user,
    loading: state.auth.loginLoading,
  }));
  // console.log(loginData);
  // const defaultValues = {
  //   email: 'demo@minimals.cc',
  //   password: 'demo1234',
  // };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    // defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (loginFormData) => {
    const credentials = {
      state: loginFormData,
      dispatch,
      navigate,
    };

    dispatch(LoginAdminApi(credentials));
  });

  const renderHead = (
    <>
      {isMobile && (
        <Stack direction="row">
          <LogoLg
            color="dark"
            sx={{
              mb: { xs: 8, md: 5 },
            }}
          />
        </Stack>
      )}
      <Stack spacing={2} sx={{ mb: 3 }}>
        <Typography variant="h4">Sign in to Snow Hill</Typography>
      </Stack>
    </>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="email" label="Email" />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {/* <Link
        href={`${paths.auth.forgot_password}?next=admin_login`}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        Forgot password?
      </Link> */}

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || loading}
      >
        Login
      </LoadingButton>
    </Stack>
  );

  return (
    <Stack
      flexDirection="column"
      justifyContent="center"
      p={3}
      sx={{
        backgroundColor: 'white',
        borderRadius: '10px',
        minHeight: 400,
        // marginTop: isMobile ? '250px' : '',
      }}
    >
      {renderHead}

      {!!errorMsg && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMsg}
        </Alert>
      )}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
    </Stack>
  );
}

JwtLoginView.propTypes = {
  role: PropTypes.string,
};
