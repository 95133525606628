import { createAsyncThunk } from '@reduxjs/toolkit';

import { activeSnack } from '../store/common';
import { get, put, post, patch } from './http';

export const getInvestors = createAsyncThunk(
  'investor/single',
  async ({ page, search, limit, sortDate, dispatch, selfData }) => {
    try {
      const response = await get(
        `/investor/investor?page=${page && page}&search=${search ?? ''}&page_size=${
          limit || 5
        }&start_date=${sortDate?.start_date || ''}&end_date=${sortDate?.end_date || ''}&self-data=${selfData || ''}`
      );
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const investorCreate = createAsyncThunk(
  'member/create',
  async ({ state, dispatch, handleClose }) => {
    try {
      const URL = `/investor/investor/`;
      const response = await post(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_investors`),
          search: '',
          dispatch,
        };
        dispatch(getInvestors(cred));
        dispatch(
          activeSnack({
            type: 'success',
            message: 'Investor created successfully',
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      // console.log('errr', error?.-response?.data[0]);
      // dispatch(
      //   activeSnack({
      //     type: 'error',
      //     message:
      //       error?.response?.data &&
      //       (error?.response?.data?.certificate_number[0] ||
      //         (error?.response?.data?.length > 0 && error?.response?.data) ||
      //         'something went wrong'),
      //   })
      // );
      dispatch(
        activeSnack({
          type: 'error',
          message: (() => {
            const errorData = error?.response?.data;

            // Check if errorData exists
            if (errorData) {
              // Check if certificate_number exists and has a value
              if (errorData?.certificate_number && errorData?.certificate_number[0]) {
                return errorData.certificate_number[0];
              }
              // Check if errorData is an array with length > 0
              if (Array.isArray(errorData) && errorData.length > 0) {
                return errorData.join(', '); // Assuming errorData is an array of strings or messages
              }
              // If errorData is an object, return its string representation
              if (typeof errorData === 'object' && !Array.isArray(errorData)) {
                return JSON.stringify(errorData);
              }
            }
            // Fallback message
            return 'Something went wrong';
          })(),
        })
      );

      throw error?.response?.data?.message;
    }
  }
);

export const getInvestorDetails = createAsyncThunk(
  'investor/view',
  async ({ investorId, dispatch }) => {
    try {
      const URL = `/investor/investor/${investorId}`;
      const response = await get(URL);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateInvestorDetails = createAsyncThunk(
  'member/create',
  async ({ state, investorId, dispatch, handleClose }) => {
    try {
      const URL = `/investor/investor/${investorId}/`;
      const response = await put(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        // const cred = {
        //   investorId: response?.id,
        //   dispatch,
        // };
        // dispatch(getInvestorDetails(cred));
        dispatch(activeSnack({ type: 'success', message: 'Investor updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const resentPassword = createAsyncThunk(
  'reset/password/admin',
  async ({ state, investorId, dispatch }) => {
    try {
      const URL = `/resent/password/${investorId}/`;
      const response = await put(URL, state);
      if (response) {
        // const cred = {
        //   investorId: response?.id,
        //   dispatch,
        // };
        // dispatch(getInvestorDetails(cred));
        dispatch(
          activeSnack({
            type: 'success',
            message: 'New password successfully sent to your registered email.',
          })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const updateInvestorPassword = createAsyncThunk(
  'investor/password/update',
  async ({ state, enqueueSnackbar, handleClose }) => {
    try {
      const response = await post(`user/change-password/`, state);

      if (response) {
        enqueueSnackbar('Password Updated Successfully!');

        // navigate(paths?.auth?.login);
        if (handleClose) {
          handleClose();
        }
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updateInvestorProfileImage = createAsyncThunk(
  'investor/create',
  async ({ state, investorId, dispatch, handleClose }) => {
    try {
      const URL = `/investor/investor/${investorId}/`;
      const response = await patch(URL, state);
      if (response) {
        if (handleClose) {
          handleClose();
        }
        // const cred = {
        //   investorId: response?.id,
        //   dispatch,
        // };
        // dispatch(getInvestorDetails(cred));
        dispatch(activeSnack({ type: 'success', message: 'Profile Image updated successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      console.log(error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
