import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { partnerProfile, user } = useSelector((state) => ({
    // partnerProfile: state.partner.profile,
    user: state.auth.user,
  }));
  // console.log(partnerProfile?.partner_code);
  // console.log(partnerProfile);
  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        {/* <Box sx={{ position: 'relative' }}>
          <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ width: 48, height: 48 }}>
            {user?.displayName?.charAt(0).toUpperCase()}
          </Avatar>

          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Free
          </Label>
        </Box> */}

        <Stack spacing={0} sx={{ mb: 2, mt: 1.5, width: 1 }}>
          {/* <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%' }}
          >
            <Typography variant="subtitle2" noWrap>
              from
            </Typography>
            &nbsp;
            <Typography variant="subtitle2" noWrap sx={{ color: '#1881bb' }}>
              
            </Typography>
          </Stack> */}
          {user && user.user_roles && user.user_roles.includes('partner') && (
            <Typography sx={{ color: '#ff9800' }}>{partnerProfile?.partner_code}</Typography>
          )}

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            version 1.5.0
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
