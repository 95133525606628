import PropTypes from 'prop-types';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, Button, Tooltip, CardMedia, Typography } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { fDate } from 'src/utils/format-time';

import editGrey from 'src/assets/other/editGrey.png';
// ----------------------------------------------------------------------

export default function InvestorTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { email, country_code, phone, full_name, id, share_number, date_added } = row;

  return (
    <TableRow hover selected={selected}>
      <TableCell>{share_number}</TableCell>
      <TableCell>{date_added ? fDate(date_added) : '-----'}</TableCell>

      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Link href={`${paths?.dashboard?.investor_view(id)}`} sx={{ cursor: 'default' }}>
          <Tooltip title="View Investor Details">
            <Typography variant="body2" color="#7A4100" sx={{ textDecoration: 'underline' }}>
              {full_name}
            </Typography>
          </Tooltip>
        </Link>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        +{country_code} {phone}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>{email || '---'}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Button
          sx={{ px: 3 }}
          size="small"
          variant="outlined"
          onClick={onEditRow}
          startIcon={<CardMedia component="img" src={editGrey} alt="copy" sx={{ width: '15px' }} />}
        >
          {' '}
          Edit
        </Button>
      </TableCell>
    </TableRow>
  );
}

InvestorTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
