import PropTypes from 'prop-types';

import { Grid, Stack, TextField, InputAdornment } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function InvestorToolbar({ onSearch, date, setDate }) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'start' : 'center'}
      spacing={2}
    >
      <Grid container>
        <Grid item pl={1} xs={12} md={4}>
          <Stack direction="row" mb={isMobile && 2}>
            <TextField
              name="date"
              label="Start Date"
              sx={{ width: '100%', mr: 2 }}
              size="medium"
              type="date"
              variant="outlined"
              inputProps={{ maxLength: 4 }}
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDate({
                  ...date,
                  start_date: e.target.value,
                })
              }
            />
            <TextField
              name="date"
              label="End Date"
              sx={{ width: '100%' }}
              size="medium"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(e) =>
                setDate({
                  ...date,
                  end_date: e.target.value,
                })
              }
            />
          </Stack>
        </Grid>
        <Grid item pl={1} xs={12} md={8}>
          <TextField
            onChange={(e) => onSearch(e.target.value)}
            placeholder="Search name, phone, email..."
            sx={{ width: '100%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

InvestorToolbar.propTypes = {
  onSearch: PropTypes.any,
  date: PropTypes.any,
  setDate: PropTypes.any,
};
