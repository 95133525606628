import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

import { Tab, Tabs, alpha, Stack, Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function InvestorSort({ sort, setSort, setOpenInvestorModalDialog }) {
  const theme = useTheme();
  // const { role } = useGetRoles();
  const TABS = [
    {
      value: '',
      label: 'All',
      color: 'default',
    },
  ];
  return (
    <Stack justifyContent="space-between" flexDirection="row">
      <Tabs
        value={sort}
        onChange={(e, newValue) => {
          setSort(newValue);
        }}
        sx={{
          px: 2.5,
          boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {TABS?.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            iconPosition="end"
            // icon={
            //   <Label
            //     variant={((tab.value === 'all' || tab.value === sort) && 'filled') || 'soft'}
            //     color={tab.color}
            //   >
            //     {tab.count}
            //   </Label>
            // }
          />
        ))}
      </Tabs>
      <Stack p={1}>
        <Button
          onClick={() => setOpenInvestorModalDialog(true)}
          variant="contained"
          sx={{
            backgroundColor: '#212B36',
            '&:hover': {
              backgroundColor: '#212B36',
            },
            width: '130px',
          }}
        >
          Create Investor
        </Button>
      </Stack>
    </Stack>
  );
}

InvestorSort.propTypes = {
  setSort: PropTypes.func,
  sort: PropTypes.string,
  setOpenInvestorModalDialog: PropTypes.func,
};
