// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  website: '/website',
};

// ----------------------------------------------------------------------

export const paths = {
  public: {
    landing: '/landing',
    academy_landing: ROOTS.LMS,
    course_view: (id) => `${ROOTS.LMS}/course/${id}`,
    plan: `/plan`,
    about: `/about`,
    contact: `/contact`,
    terms_conditions: `/terms/conditions`,
  },
  // AUTH
  auth: {
    login: `/login/admin`,
    login_investor: `/login`,
    login_otp_investor: `/login/otp`,

    register: `/register`,
    forgot_password: `/forgot/password`,

    logout: `${ROOTS.AUTH}/logout`,

    create_password: (type, token) => `/create/password/:${type}/:${token}`,
    forgot_password_email: (type, token) => `/password/reset/:${token}`,
    password_verification: (type, token) => `/invitation/:${type}/:${token}`,
  },
  website: {},
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    onBoarding: '/on-boarding',
    investor_view: (id) => `${ROOTS.DASHBOARD}/investor/${id}`,

    profile: `${ROOTS.DASHBOARD}/profile`,
    referral: `${ROOTS.DASHBOARD}/referral`,
    transaction: `${ROOTS.DASHBOARD}/transactions`,
    income: `${ROOTS.DASHBOARD}/income`,
    club: `${ROOTS.DASHBOARD}/clubs`,
    withdrawal_requests: `${ROOTS.DASHBOARD}/withdrawal/requests`,

    member: `${ROOTS.DASHBOARD}/member`,
    member_view: (id) => `${ROOTS.DASHBOARD}/member/${id}`,
    member_create: `${ROOTS.DASHBOARD}/member/create`,

    settings: `${ROOTS.DASHBOARD}/settings`,
  },
};

export const AuthRoles = {
  admin: 'Admin',
  member: 'Member',
};
