import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllDistrict = createAsyncThunk(
  'district/list',
  async ({ page, search, dispatch, limit, state }) => {
    const URL = `/investor/district/?page=${page && page}&search=${search && search}&limit=${limit || 5}`;
    const URL_FILTER = `/investor/district/?page=${page && page}&search=${search && search}&limit=${limit || 5}&state=${state}`;
    try {
      const response = await get(state ? URL_FILTER : URL);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const getAllState = createAsyncThunk(
  'state/list',
  async ({ page, search, dispatch, limit }) => {
    try {
      const response = await get(
        `/investor/state/?page=${page && page}&search=${search && search}&limit=${limit || 5}`
      );
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
