import * as React from 'react';
import PropTypes from 'prop-types';

// components
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import { Stack, Dialog, IconButton, DialogTitle, DialogContent } from '@mui/material';

// import { createEnquiry, updateEnquiryDetails } from 'src/server/api/enquiry';

import { useResponsive } from 'src/hooks/use-responsive';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import OnBoardingStepper from 'src/components/stepper/stepper';

import InvestorCrudModalContent from './InvestorCrudModalContent';

InvestorCrudModalDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isUpdate: PropTypes.object,
  setIsUpdate: PropTypes.any,
  setTab: PropTypes.any,
};

export default function InvestorCrudModalDialog({ open, setOpen, isUpdate, setIsUpdate, setTab }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useResponsive('down', 'md');
  const { role } = useGetRoles();

  const steps = ['Profile', 'Address', 'Bank'];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
    setIsUpdate({});
  };

  // React.useEffect(() => {
  //   if (setTab) {
  //     setActiveStep(setTab);
  //   }
  // }, [setTab]);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getTitle = () => {
    if (role !== 'admin') {
      return isUpdate?.id ? 'Profile Update' : 'Create Investor';
    }
    return isUpdate?.id ? 'Update Investor' : 'Create Investor';
  };
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', top: 0, right: 0, m: 1 }}
        >
          <CloseIcon sx={{ fontSize: '20px' }} />
        </IconButton>
        <DialogTitle id="responsive-dialog-title">
          {/* {isUpdate?.id ? 'Update Investor' : 'Create Investor'} */}
          {getTitle()}
        </DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '200px' : '580px', padding: '0px' }}>
          <OnBoardingStepper activeStep={activeStep} steps={steps} />

          <Stack>
            <InvestorCrudModalContent
              activeStep={activeStep}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              handleClose={handleClose}
              isUpdate={isUpdate}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
