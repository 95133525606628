import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { Paper, TableRow, TableCell, Typography, CircularProgress } from '@mui/material';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------
import { getInvestors } from 'src/server/api/investor';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import InvestorSort from 'src/sections/admin/investor/InvestorSort';
import InvestorToolbar from 'src/sections/admin/investor/InvestorToolbar';
import InvestorTableRow from 'src/sections/admin/investor/InvestorTableRow';
import InvestorCrudModalDialog from 'src/sections/admin/investor/InvestorCrudModal';

export default function Investors() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const router = useRouter();
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  const [date, setDate] = useState('');

  // const isMobile = useResponsive('down', 'md');

  const { loading, InvestorList } = useSelector((state) => ({
    loading: state.referrals.loading,
    InvestorList: state.investor.investors,
  }));

  const [openInvestorModalDialog, setOpenInvestorModalDialog] = useState(false);
  const [investorData, setInvestorData] = useState({});

  const identifier = 'investors';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);

  const [searchText, setSearchText] = useState();
  const [sort, setSort] = useState('');

  const [tableData, setTableData] = useState([]);
  const totalPage = InvestorList?.count;

  const TABLE_HEAD = [
    { id: 'created', label: 'Investor ID' },
    { id: 'joinedOn', label: 'Joined On' },
    { id: 'member_id', label: 'Name' },
    { id: 'name', label: 'Phone' },
    { id: 'phone', label: 'Email' },
    { id: 'email', label: 'Action' },
  ];

  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      search: searchText || '',
      limit: storedRowsPerPage || table.rowsPerPage,
      sortDate: date,
      dispatch,
      selfData: '',
    };
    dispatch(getInvestors(credentials));
  }, [date, dispatch, searchText, storedRowsPerPage, table.page, table.rowsPerPage]);

  useEffect(() => {
    setTableData(InvestorList?.results);
  }, [InvestorList]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = (item) => {
    setOpenInvestorModalDialog(true);
    setInvestorData(item);
  };

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );

  const handleSearch = (value) => {
    setSearchText(value);
  };

  return (
    <>
      {openInvestorModalDialog && (
        <InvestorCrudModalDialog
          setOpen={setOpenInvestorModalDialog}
          open={openInvestorModalDialog}
          isUpdate={investorData}
          setIsUpdate={setInvestorData}
        />
      )}
      <Stack spacing={3}>
        <Stack p={2}>
          <Typography variant="h6">Investors({InvestorList?.count || 0})</Typography>
        </Stack>
        <Card>
          <InvestorSort
            sort={sort}
            setSort={setSort}
            setOpenInvestorModalDialog={setOpenInvestorModalDialog}
          />
          <Stack mx={2} my={3}>
            <InvestorToolbar date={date} setDate={setDate} onSearch={handleSearch} />
          </Stack>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
            />
            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                />

                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <CircularProgress color="inherit" />
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <InvestorTableRow
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                    />
                  ))}
                </TableBody>

                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
