import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useGetToken } from 'src/hooks/useHandleSessions';

import { TOKEN_PREFIX } from 'src/server/api/http';

import { SplashScreen } from 'src/components/loading-screen';

export default function AuthGuard({ children }) {
  const { loading } = useSelector((state) => ({
    loading: state.auth.loginLoading,
  }));

  return <>{loading ? <SplashScreen /> : <Container> {children}</Container>}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

function Container({ children }) {
  const router = useRouter();

  const { token } = useGetToken(TOKEN_PREFIX);
  const [checked, setChecked] = useState(false);

  // console.log(token);

  const check = useCallback(() => {
    if (!token) {
      router.replace(paths.auth.login_investor);
    } else {
      setChecked(true);

      // dispatch(getInvestors(credentials));
    }
  }, [router, token]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}

Container.propTypes = {
  children: PropTypes.node,
};
