import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useLocation } from 'react-router';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import logoDarkPng from 'src/assets/logo/logo_dark.png';
import logoWhitePng from 'src/assets/logo/logo_white.png';

import Image from '../image';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const LogoLg = forwardRef(({ disabledLink = false, color, sx, ...other }, ref) => {
  const settings = useSettingsContext();
  const location = useLocation();
  const selectedLogo = color === 'light' ? logoWhitePng : logoDarkPng;

  const logoLight = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <Image objectFit="contain" alt="logo" src={selectedLogo} sx={{ height: '100px' }} />
    </Box>
  );

  const logoDark = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <Image objectFit="contain" alt="logo" src={logoWhitePng} sx={{ height: '100px' }} />
    </Box>
  );

  if (disabledLink) {
    return logoLight;
  }

  return (
    <Link
      component={RouterLink}
      href={location?.pathname === '/login/admin' ? paths.auth.login : paths.auth.login_investor}
      sx={{ display: 'contents' }}
    >
      {settings.themeMode === 'light' ? logoLight : logoDark}
    </Link>
  );
});

LogoLg.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
  color: PropTypes.any,
};

export default LogoLg;
