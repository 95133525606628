import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetRole, useSetToken } from 'src/hooks/useHandleSessions';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

export const forgotPassword = createAsyncThunk(
  'auth/forgot',
  async ({ state, enqueueSnackbar, handleRoles, setOpenOTPSuccess }) => {
    try {
      const response = await post(`user/forget-password-email/`, state);
      if (response) {
        enqueueSnackbar('Password generated successfully!');
        if (handleRoles) {
          localStorage.setItem('userRole', handleRoles());
        }
        if (setOpenOTPSuccess) {
          setOpenOTPSuccess(true);
        }
        useSetToken(response?.token);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);
export const updatePassword = createAsyncThunk(
  'auth/password/update',
  async ({ state, enqueueSnackbar, token, navigate, userType }) => {
    try {
      const response = await post(`password/reset/${token}`, state);

      if (response) {
        enqueueSnackbar('Password Updated Successfully!');
        useSetToken(response?.token);
        if (userType === 'member') {
          navigate(paths.auth.login_investor);
        } else {
          navigate(paths.auth.login);
        }
        // navigate(paths?.auth?.login);

        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const LoginApi = createAsyncThunk('auth/login', async ({ state, dispatch, navigate }) => {
  try {
    const response = await post('/user/login-email-pass/', state);
    // if (response.is_verified === true) {
    //   dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated' }));

    //   useSetToken(response?.token);
    //   useSetRole(response?.role);
    //   navigate(paths?.dashboard?.root, { replace: true });
    //   return response;
    // }

    // navigate(paths?.auth?.login_otp_investor, { replace: true });
    useSetToken(response?.token);
    useSetRole(response?.role);
    if (navigate) {
      navigate(paths?.dashboard?.root, { replace: true });
    }
    dispatch(
      activeSnack({
        type: 'success',
        message: 'Successfully Authenticated',
      })
    );
    return response;
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error }));
    throw error?.response?.data?.error;
  }
});
export const LoginAdminApi = createAsyncThunk(
  'auth/admin/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/user/login-email-pass/', state);

      // if (response.is_verified === true) {
      //   dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated' }));

      useSetToken(response?.token);
      useSetRole(response?.role);
      if (navigate) {
        navigate(paths?.dashboard?.root, { replace: true });
      }

      dispatch(
        activeSnack({
          type: 'success',
          message: 'Successfully Authenticated',
        })
      );
      return response;
    } catch (error) {
      // console.log('error=>', error);
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.error }));
      throw error?.response?.data?.error;
    }
  }
);

export const logoutApi = createAsyncThunk('auth/logout', async ({ dispatch, navigate }) => {
  try {
    const response = await get('/logout');
    if (response) {
      sessionStorage.clear();
      dispatch(activeSnack({ type: 'success', message: 'logged out successfully' }));
      navigate(paths?.dashboard?.root, { replace: true });
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    sessionStorage.clear();
    navigate(paths?.dashboard?.root, { replace: true });
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});

export const memberRegisterApi = createAsyncThunk(
  'auth/register',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('/member/register', state);

      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Successfully Registered' }));
        // navigate(paths?.auth?.login_otp_investor);
        navigate(`${paths?.auth?.login_otp_investor}?register`);

        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const CheckUserName = createAsyncThunk('check/username', async ({ state }) => {
  try {
    const URL = `/check/username`;
    const response = await post(URL, state);
    if (response) {
      // dispatch(activeSnack({ type: 'success', message: response.message }));
      return response;
    }
    return false;
  } catch (error) {
    throw error?.response?.data?.message;
  }
});

export const verifyOtpApi = createAsyncThunk(
  'auth/otp',
  async ({ state, dispatch, navigate, enqueueSnackbar }) => {
    try {
      const response = await post('/user/verify-phone-otp/', state);
      console.log(response);
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);

        if (enqueueSnackbar) {
          enqueueSnackbar(' OTP Verified Successfully!');
        }
        if (dispatch) {
          dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated' }));
        }
        if (navigate) {
          navigate(paths?.dashboard?.root, { replace: true });
        }

        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      console.log(error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  }
);

export const getMemberProfile = createAsyncThunk(
  'user/profile',
  async ({ enqueueSnackbar, dispatch }) => {
    try {
      const response = await get(`/me`);
      if (response?._id) {
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const updateMemberProfile = createAsyncThunk(
  'user/profile/update',
  async ({ enqueueSnackbar, state }) => {
    try {
      const response = await put(`/user/update`, state);
      if (response?._id) {
        enqueueSnackbar('Admin Profile Updated Successfully', state);
        return response;
      }
      return enqueueSnackbar('something went wrong', { variant: 'error' });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

// member login
export const memberLoginApi = createAsyncThunk(
  'auth/student/login',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post('member/login', state);
      dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));
      if (response) {
        useSetToken(response?.token);
        useSetRole(response?.role);
        navigate(paths.dashboard?.root, { replace: true });
        return response;
      }

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const ResentOtpApi = createAsyncThunk(
  'resend/otp',

  async ({ state, dispatch, handleNextStep, navigate }) => {
    try {
      const response = await post('/user/resend-login-phone-otp/', state);
      console.log(response);
      if (response) {
        // dispatch(
        //   activeSnack({
        //     type: 'success',
        //     message: '"An OTP has been successfully sent to your registered email.. ',
        //   })
        // );
        if (dispatch) {
          dispatch(
            activeSnack({
              type: 'success',
              message: '"An OTP has been successfully sent to your registered Phone.. ',
            })
          );
        }
        if (navigate) {
          navigate();
        }
        // handleNextStep();
        return response;
      }
      // dispatch(activeSnack({ type: 'success', message: 'Successfully Authenticated ' }));

      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
export const EnquiryApi = createAsyncThunk('/enquiry/new', async ({ state, dispatch, reset }) => {
  try {
    const response = await post('/enquiry/new', state);

    if (response) {
      dispatch(activeSnack({ type: 'success', message: 'Successfully Submitted' }));
      if (reset) {
        return reset();
      }
      return response;
    }

    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
