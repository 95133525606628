import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app';
import authReducer from './auth';
import commonReducer from './common';
import memberReducer from './member';
import investorReducer from './investor';
import settingsReducer from './settings';
import locationReducer from './location';
import referralsReducer from './referral';
import dashboardReducer from './dashboard';

export default configureStore({
  reducer: {
    common: commonReducer,
    auth: authReducer,
    app: appReducer,
    dashboard: dashboardReducer,
    member: memberReducer,
    settings: settingsReducer,
    referrals: referralsReducer,
    investor: investorReducer,
    location: locationReducer,
  },
});
