import { createSlice } from '@reduxjs/toolkit';

import { getAllReferrals } from '../api/referrals';

export const referralSlice = createSlice({
  name: 'referral',
  initialState: {
    loading: false,
    error: {},
    referrals: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all referrals
    [getAllReferrals.pending]: (state) => {
      state.loading = true;
    },
    [getAllReferrals.fulfilled]: (state, action) => {
      state.loading = false;
      state.referrals = action.payload;
      state.error = {};
    },
    [getAllReferrals.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = referralSlice.actions;

export default referralSlice.reducer;
