import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import { RegisterView } from 'src/sections/auth';

// ----------------------------------------------------------------------

export default function RegisterPage() {
  return (
    <>
      <MetaHelmet title="Register" />

      <RegisterView />
    </>
  );
}
