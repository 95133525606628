import { useSelector } from 'react-redux';

import Stack from '@mui/material/Stack';

import { useResponsive } from 'src/hooks/use-responsive';

import AccountGeneral from './account-general';
// ----------------------------------------------------------------------

// const TABS = [
//   {
//     value: 'general',
//     label: 'General',
//     icon: <Iconify icon="solar:user-id-bold" width={24} />,
//   },

//   {
//     value: 'security',
//     label: 'Security',
//     icon: <Iconify icon="ic:round-vpn-key" width={24} />,
//   },
// ];

// ----------------------------------------------------------------------

export default function AccountView() {
  // const location = useLocation();
  // const settings = useSettingsContext();
  const isMobile = useResponsive('down', 'md');

  const { MemberProfileDetails } = useSelector((state) => ({
    loading: state.member.loading,
    MemberProfileDetails: state.member.memberProfileDetails.member,
  }));
  console.log(MemberProfileDetails);

  return (
    <Stack>
      <Stack flexDirection={isMobile ? 'column' : 'row'} gap={3} mb={3}>
        <Stack flex={3.9}>{/* <PersonalInfo memberDetails={MemberProfileDetails} /> */}</Stack>
        <Stack flex={8.1}>{/* <WalletAddressMember data={MemberProfileDetails} /> */}</Stack>
      </Stack>
      <AccountGeneral />
    </Stack>
  );
}
