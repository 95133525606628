import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  getInvestors,
  investorCreate,
  getInvestorDetails,
  updateInvestorDetails,
} from '../api/investor';

export const investorSlice = createSlice({
  name: 'Investor',
  initialState: {
    loading: false,
    error: {},
    investorDetails: {},
    investorProfileDetails: {},
    investors: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [investorCreate.pending]: (state) => {
      state.loading = true;
    },
    [investorCreate.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.investors;
      const newList = jsonState?.investors;

      const modifiedList = {
        ...jsonState,
        investors: Array.isArray(newList) ? [action.payload, ...newList] : [action.payload],
      };
      state.loading = false;
      state.investorDetails = action.payload;
      state.investors = modifiedList;
      state.error = {};
    },
    [investorCreate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // member profile
    [getInvestors.pending]: (state) => {
      state.loading = true;
    },
    [getInvestors.fulfilled]: (state, action) => {
      state.loading = false;
      state.investors = action.payload;
      state.investorProfileDetails = action.payload;
      state.error = {};
    },
    [getInvestors.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [getInvestorDetails.pending]: (state) => {
      state.loading = true;
    },
    [getInvestorDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.investorDetails = action.payload;
      state.error = {};
    },
    [getInvestorDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateInvestorDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateInvestorDetails.fulfilled]: (state, action) => {
      state.loading = false;
      const jsonState = covertToJSON(state)?.investors;
      const modifiedList = {
        ...jsonState,
        results: jsonState.results?.map((i) =>
          i.id === action?.payload?.id ? action?.payload : i
        ),
      };
      state.investors = modifiedList;
      state.investorDetails = action.payload;
      state.investorProfileDetails = action.payload;
      state.error = {};
    },
    [updateInvestorDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = investorSlice.actions;

export default investorSlice.reducer;
