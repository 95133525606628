import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthRoles } from 'src/routes/routes/paths';

// import SettingsPage from 'src/pages/Admin/settings';
import investor from 'src/assets/other/investor.png';
import LoginAdmin from 'src/assets/other/Snowhill.png';
import RegisterPage from 'src/pages/Common/Auth/Register';

import { SplashScreen } from 'src/components/loading-screen';

const InvestorSingleView = lazy(() => import('src/pages/Admin/investorSingleView'));

const AuthLayout = lazy(() => import('src/layouts/auth/authLayout'));
const LogoutPage = lazy(() => import('src/pages/Common/Auth/LogoutPage'));
const LoginAdminPage = lazy(() => import('src/pages/Common/Auth/Login'));
const LoginInvestorPage = lazy(() => import('src/pages/Common/Auth/InvestorLogin'));
const LoginInvestorOTPPage = lazy(() => import('src/pages/Common/Auth/InvestorOTPLogin'));

const NewPasswordPage = lazy(() => import('src/pages/Common/Auth/NewPassword'));
const ForgotPasswordPage = lazy(() => import('src/pages/Common/Auth/ForgotPassword'));
const AuthModernCompactLayout = lazy(() => import('src/layouts/auth/modern-compact'));
const RegisterLayout = lazy(() => import('src/layouts/auth/registerLayoutNew'));
const Settings = lazy(() => import('src/pages/Admin/settings'));

const CreatePassword = lazy(() => import('src/sections/auth/new-password-view'));

// const LandingPage = lazy(() => import('src/pages/Common/LandingWeb/LandingPage'));
const CompactLayout = lazy(() => import('src/layouts/compact'));
// const PlanPage = lazy(() => import('src/pages/Common/website/plan'));
// const AboutPage = lazy(() => import('src/pages/Common/website/about'));
// const ContactPage = lazy(() => import('src/pages/Common/website/contact'));
// const TermsAndConditionPage = lazy(() => import('src/pages/Common/website/terms&conditions'));

const Page500 = lazy(() => import('src/pages/Common/Static/500'));
const Page403 = lazy(() => import('src/pages/Common/Static/403'));
const Page404 = lazy(() => import('src/pages/Common/Static/404'));

const publicRoutes = [
  // {
  //   element: (
  //     <Suspense fallback={<SplashScreen />}>
  //       <Outlet />
  //     </Suspense>
  //   ),
  //   children: [
  //     { path: 'landing', element: <LandingPage /> },
  //     { path: 'plan', element: <PlanPage /> },
  //     { path: 'about', element: <AboutPage /> },
  //     { path: 'contact', element: <ContactPage /> },
  //     { path: 'terms/conditions', element: <TermsAndConditionPage /> },
  //   ],
  // },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
    ],
  },
];

const authRoutes = [
  {
    path: '',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),

    children: [
      {
        path: 'login',
        element: <Outlet />,
        children: [
          {
            path: 'admin',
            element: (
              <AuthLayout
                welcomeText="Hi, Welcome back !"
                image={LoginAdmin}
                title="Authenticate as an administrator"
                userRole="admin"
              >
                <LoginAdminPage role={AuthRoles.admin} />
              </AuthLayout>
            ),
          },
          {
            path: '',
            element: (
              <AuthLayout
                image={investor}
                welcomeText="Hi, Welcome back !"
                title="Authenticate as an Member"
                userRole="member"
              >
                <LoginInvestorPage role={AuthRoles.member} />
              </AuthLayout>
            ),
          },
          {
            path: 'otp',
            element: (
              <AuthLayout image={LoginAdmin} title="Authenticate as an Member" userRole="member">
                <LoginInvestorOTPPage role={AuthRoles.member} />
              </AuthLayout>
            ),
          },
        ],
      },

      {
        path: 'logout',
        element: <LogoutPage />,
      },
      {
        path: 'forgot/password',
        element: (
          <AuthModernCompactLayout>
            <ForgotPasswordPage />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create/password/:type/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="create" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'password/reset/:token',
        element: (
          <AuthModernCompactLayout>
            <NewPasswordPage type="reset" />
          </AuthModernCompactLayout>
        ),
      },

      {
        path: 'create/password/:id',
        element: (
          <AuthModernCompactLayout>
            <CreatePassword />
          </AuthModernCompactLayout>
        ),
      },
      {
        path: 'register',
        element: (
          <RegisterLayout
            welcomeText="Welcome To snowhill 
          Higher Profit And Enjoy The Profit"
          >
            <RegisterPage />
          </RegisterLayout>
        ),
      },
      { path: '404', element: <Page404 isInner /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];

// super admin
const adminRoutes = [
  // {
  //   path: 'dashboard',
  //   element: <AdminWidgetSummary />,
  // },

  {
    path: 'investor/:id',
    element: <InvestorSingleView />,
  },

  {
    path: 'settings',
    element: <Settings />,
  },
];
// super admin
const memberRoutes = [
  {
    path: 'referral',
    // element: <ReferralPage />,
  },
];

export { authRoutes, adminRoutes, publicRoutes, memberRoutes };
