import { useSelector } from 'react-redux';

// @mui
import { Container } from '@mui/material';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import DashboardContentAdmin from 'src/pages/Admin/admin';

import { useSettingsContext } from 'src/components/settings';
import DashboardLoading from 'src/components/loading/DashboardLoading';

// components

// action

// sections

// ----------------------------------------------------------------------

export default function DashboardAdminApp() {
  const settings = useSettingsContext();

  const { dashLoading } = useSelector((state) => ({
    dashLoading: state.dashboard.loading,
  }));

  return (
    <>
      <MetaHelmet title="Dashboard" />

      <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ mt: 4 }}>
        {!dashLoading ? <DashboardContentAdmin /> : <DashboardLoading />}
      </Container>
    </>
  );
}
