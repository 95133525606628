import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { alpha } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Chip, Stack, Button, IconButton, Typography, CircularProgress } from '@mui/material';

import { fData } from 'src/utils/format-number';

import uploadImg from 'src/assets/other/upload.png';

import { post } from '../../server/api/http';
import { RHFUploadAvatar } from '../hook-form';
import { activeSnack } from '../../server/store/common';

export const FileUploadCustom = ({
  title,
  uploadUrl,
  deleteUrl,
  setImagesPreview,
  imagesPreview,
  isMultiple,
  theme,
  onChangeUpdate,
  isUpdate,
}) => {
  const dispatch = useDispatch();
  // const uploadBaseUrl = `/file/admin/upload/${uploadUrl}`;
  const deleteDefaultUrl = deleteUrl || '/file/admin/delete';
  const imageSize = 2;

  const [fileSizeIssue, setFileSizeIssue] = useState('');
  const [deleteLoad, setDeleteLoad] = useState({
    status: false,
    id: '',
  });
  const [uploadLoad, setUploadLoad] = useState(false);

  // size of the file
  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (bytes === 0) return '0 Byte';
    return {
      size: `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`,
      sizeInKb: Math.round(bytes / 1024),
    };
  }

  // Function to handle file selection
  const handleFileSelect = (acceptedFiles) => {
    setUploadLoad(true);

    const files = acceptedFiles;

    const { size, sizeInKb } = bytesToSize(files[0]?.size);

    if (sizeInKb >= imageSize * 1024) {
      setFileSizeIssue(`${size} file size is too large - Max Limit ${imageSize}MB`);
    } else {
      setFileSizeIssue('');
      uploadingImage(files);
    }

    setUploadLoad(false);
  };

  const uploadingImage = (files) => {
    setImagesPreview({
      image: files[0],
    });
  };

  // Hook for handling file drop and selection
  const { getRootProps, getInputProps } = useDropzone({
    // accept: '.pdf', // Change the file extension based on your requirement
    multiple: false,
    onDrop: handleFileSelect,
  });

  const handleRemove = async (id) => {
    try {
      setDeleteLoad({
        status: true,
        id,
      });
      const res = await post(deleteDefaultUrl, {
        public_id: id,
      });
      if (
        res?.result === 'ok' ||
        res?.result === 'not found' ||
        res?.message === 'File deleted successfully'
      ) {
        setImagesPreview(imagesPreview?.filter((img) => img.public_id !== id));
        dispatch(activeSnack({ type: 'success', message: 'Image removed' }));
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Image delete fail, try again' }));
      }
      setDeleteLoad({
        status: false,
        id,
      });
      if (isUpdate && onChangeUpdate) {
        onChangeUpdate(id, 'remove');
      }
    } catch (error) {
      setDeleteLoad({
        status: false,
        id,
      });
      dispatch(activeSnack({ type: 'error', message: 'Image delete fail, try again' }));
    }
  };

  const handleDropNewFile = (file) => {
    handleFileSelect(file);
    theme?.handleDrop(file);
  };

  return (
    <div>
      {!isMultiple && imagesPreview?.length >= 1 ? (
        ''
      ) : (
        <>
          {theme?.type === 'simple-square' && (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack
                flexDirection="column"
                alignItems="center"
                backgroundColor="rgba(145, 158, 171, 0.1)"
                borderRadius="15px"
                border={(themes) => `1px dashed ${alpha(themes.palette.grey[500], 0.2)}`}
                padding="8px"
                paddingY="2.2rem"
              >
                <div>
                  <Stack sx={{ alignItems: 'center' }}>
                    {/* icons/solid/ic-eva:cloud-upload-fill */}
                    {/* <Iconify icon="ic-eva:cloud-upload-fill" />  */}
                    <CloudUploadIcon sx={{ color: '#919EAB', fontSize: '38px' }} />
                  </Stack>
                  <Typography
                    sx={{
                      color: '#919EAB',
                      fontSize: '14px',
                      fontWeight: '400',
                      textAlign: 'center',
                      marginTop: '5px',
                    }}
                  >
                    Upload file{' '}
                  </Typography>
                </div>

                {fileSizeIssue && (
                  <Chip sx={{ mx: 1 }} label={fileSizeIssue} color="error" variant="outlined" />
                )}
                {/* Square box with image */}
              </Stack>
            </div>
          )}
          {theme?.type === 'simple-square' && imagesPreview?.length >= 1 && (
            <Stack flexDirection="row">
              {imagesPreview?.map((img, key) => (
                <Stack
                  key={key}
                  direction="row"
                  alignItems="center"
                  sx={{ position: 'relative', marginTop: 3 }}
                >
                  <img
                    alt={img?.public_id}
                    src={img?.url}
                    style={{
                      width: '100px',
                      height: '80px',
                      objectFit: 'contain',
                      borderRadius: '15px',
                      filter:
                        deleteLoad?.status && deleteLoad?.id === img?.public_id
                          ? 'grayscale(1)'
                          : 'none',
                    }}
                  />
                  {/* Close icon */}
                  <IconButton
                    onClick={() => handleRemove(img?.public_id)}
                    color="error"
                    sx={{
                      position: 'absolute',
                      top: '6px',
                      right: '15px',
                      width: 30,
                      height: 30,
                      backgroundColor: 'rgba(0, 0, 0, 0.48)',
                    }}
                  >
                    <CloseIcon fontSize="small" sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          )}
          {theme?.type === 'square' && (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack
                flexDirection="column"
                alignItems="center"
                backgroundColor="rgba(145, 158, 171, 0.1)"
                borderRadius="15px"
                border="1px dotted  rgba(145, 158, 171, 0.2)"
                padding="10px"
                paddingY="3rem"
              >
                <div>
                  <Stack sx={{ alignItems: 'center' }}>
                    <img
                      src={uploadImg}
                      alt="upload-illussion"
                      style={{
                        width: '300px',
                        height: '225px',
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                    />
                  </Stack>
                  <Typography
                    sx={{
                      color: '#212B36',
                      fontSize: '18px',
                      fontWeight: '700',
                      textAlign: 'center',
                      marginTop: '5px',
                    }}
                  >
                    Select File
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#637381',
                      textAlign: 'center',
                    }}
                  >
                    Drop files here or click <span style={{ color: '#00A76F' }}>browse </span>{' '}
                    thorough your machine
                  </Typography>
                </div>

                {fileSizeIssue && (
                  <Chip sx={{ mx: 1 }} label={fileSizeIssue} color="error" variant="outlined" />
                )}
                {/* Square box with image */}
              </Stack>
            </div>
          )}
          {theme?.type === 'square' && imagesPreview?.length >= 1 && (
            <Stack flexDirection="row">
              {imagesPreview?.map((img, key) => (
                <Stack
                  key={key}
                  direction="row"
                  alignItems="center"
                  sx={{ position: 'relative', marginTop: 3 }}
                >
                  <img
                    alt={img?.public_id}
                    src={img?.url}
                    style={{
                      width: '100px',
                      height: '80px',
                      objectFit: 'contain',
                      borderRadius: '15px',
                      filter:
                        deleteLoad?.status && deleteLoad?.id === img?.public_id
                          ? 'grayscale(1)'
                          : 'none',
                    }}
                  />
                  {/* Close icon */}
                  <IconButton
                    onClick={() => handleRemove(img?.public_id)}
                    color="error"
                    sx={{
                      position: 'absolute',
                      top: '6px',
                      right: '15px',
                      width: 30,
                      height: 30,
                      backgroundColor: 'rgba(0, 0, 0, 0.48)',
                    }}
                  >
                    <CloseIcon fontSize="small" sx={{ color: 'white' }} />
                  </IconButton>
                </Stack>
              ))}
            </Stack>
          )}
          {theme?.type === 'standard' && (
            <RHFUploadAvatar
              handleRemoveImage={() => handleRemove(imagesPreview[0]?.public_id)}
              imagesPreview={imagesPreview}
              name=""
              maxSize={imageSize * 1024 * 1024}
              onDrop={handleDropNewFile}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(imageSize * 1024 * 1024)}
                </Typography>
              }
            />
          )}

          {theme?.type === 'basic' && (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack flexDirection="row" alignItems="center">
                <Button variant="outlined" color="inherit" sx={{ color: 'gray' }}>
                  {title || 'Select File'}{' '}
                  {uploadLoad && <CircularProgress size={14} sx={{ mx: 1 }} />}
                </Button>
                {fileSizeIssue && (
                  <Chip sx={{ mx: 1 }} label={fileSizeIssue} color="error" variant="outlined" />
                )}
              </Stack>
            </div>
          )}
        </>
      )}

      {theme?.type === 'basic' && imagesPreview?.length >= 1 && (
        <Stack flexDirection="row">
          {imagesPreview?.map((img, key) => (
            <Stack
              key={key}
              sx={{ mt: 3, width: '100%', backgroundColor: 'ThreeDFace', borderRadius: 1, p: 1 }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mr={2}
            >
              <Stack direction="row" alignItems="center">
                <img
                  alt={img?.public_id}
                  src={img?.url}
                  style={{
                    width: '80px',
                    height: '60px',
                    objectFit: 'contain',
                    filter:
                      deleteLoad?.status && deleteLoad?.id === img?.public_id
                        ? 'grayscale(1)'
                        : 'none',
                  }}
                />
              </Stack>
              {deleteLoad?.status && deleteLoad?.id === img?.public_id ? (
                <CircularProgress size={14} sx={{ mx: 1 }} />
              ) : (
                <IconButton
                  onClick={() => handleRemove(img?.public_id)}
                  color="error"
                  sx={{ width: 30, height: 30, mx: 1 }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>
      )}
    </div>
  );
};

FileUploadCustom.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    handleDrop: PropTypes.func,
  }),
  uploadUrl: PropTypes.string,
  deleteUrl: PropTypes.string,
  setImagesPreview: PropTypes.func,
  imagesPreview: PropTypes.any,
  isMultiple: PropTypes.bool,
  onChangeUpdate: PropTypes.any,
  isUpdate: PropTypes.any,
};

FileUploadCustom.defaultProps = {
  theme: {
    type: 'basic',
  },
};
